<template>
    <div>
              
    </div>
</template>
<script>
export default {
    name: 'redirigeWhatsApp',
    props: {
             numero: String,
             texto: String,
    },
    data(){
        return{
        }
    },
     mounted() {
          this.$router.go(-1)
          this.texto=this.texto.replace(' ','%20')
          let url= 'https://wa.me/' + this.numero + '?text=' + this.texto
          window.location.href = url
       }
}
</script>